<template>
  <div>

    <select v-model="selectedSocieteId" class="form-control w-auto" id="societe_id">
      <option disabled value="" selected>Sélectionnez une société</option>
      <option value="1" v-if="havePermission('manage_cse')">Energy</option>
      <option value="2" v-if="havePermission('manage_cse_techno')">Techno</option>
      <option value="13" v-if="havePermission('manage_cse_hoggar')">Hoggar</option>
    </select>

    <div class="rounded p-3 mb-3 mt-3" v-background-3 v-if="havePermission('manage_cse')" :style="{ display: selectedSocieteId === '1' ? 'block' : 'none' }">
      <h4>Economie et finances CSE Energy</h4>
      <uploader
        :id="'document_economie_finances'"
        url="/upload/document"
        file_system="CSE/ECO_FINANCES"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>

    <div class="rounded p-3 mb-3 mt-3" v-background-3 v-if="havePermission('manage_cse_techno')" :style="{ display: selectedSocieteId === '2' ? 'block' : 'none' }">
      <h4>Economie et finances CSE Techno</h4>
      <uploader
        :id="'document_economie_finances_techno'"
        url="/upload/document"
        file_system="CSE_TECHNO/ECO_FINANCES"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>

    <div class="rounded p-3 mb-3 mt-3" v-background-3 v-if="havePermission('manage_cse_hoggar')" :style="{ display: selectedSocieteId === '13' ? 'block' : 'none' }" >
      <h4>Economie et finances CSE Hoggar</h4>
      <uploader
        :id="'document_economie_finances_hoggar'"
        url="/upload/document"
        file_system="CSE_HOGGAR/ECO_FINANCES"
        file_field="rapport"
        :file_key="1"
        title="Liste des fichiers"
      ></uploader>
    </div>

  </div>
</template>
<script>
import Uploader from "@/components/bases/Uploader.vue";
import { mapGetters } from "vuex";

export default {
  components: { Uploader },
  data() {
    return {
      selectedSocieteId: '', // Ajoutez cette ligne pour définir selectedSocieteId dans le data
    };
  },
  methods: {
    initializeSelectedSocieteId() {
      if (this.havePermission('manage_cse')) {
        this.selectedSocieteId = '1';
      } else if (this.havePermission('manage_cse_techno')) {
        this.selectedSocieteId = '2';
      } else {
        this.selectedSocieteId = '13';
      }
    },
  },
  mounted() {
    this.initializeSelectedSocieteId();
  },
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
  },
};
</script>
